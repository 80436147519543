$app-shortcut-btn-size: 49px;

$menu-item-size: 45px;
$menu-items:9;
$menu-grid-icon: 5px;
$menu-item-direction: 'top'; //top or left

%ball{
  background:$primary-500;
  border-radius:50%;
  width:$menu-item-size;
  height:$menu-item-size;
  position:absolute !important;
  padding:0;
  right:0;
  bottom:0;
  color:$white !important;
  text-align:center;
  line-height:$menu-item-size;
  transform:translate3d(0,0,0);
  transition:transform ease-out 200ms;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05), 
              0 1px 2px rgba(0, 0, 0, 0.1);

    &:hover {
      background: $primary-700;
    }
}

.shortcut-menu {
  position:fixed;
  right: $grid-gutter-width;
  bottom: $footer-height + $grid-gutter-width;
  z-index: $footer-zindex + 1; 
}

.shortcut-menu-tabs {
  position:fixed;
  right: $grid-gutter-width;
  bottom: $footer-height + $grid-gutter-width + 3rem;
  z-index: $footer-zindex + 1; 
}

.menu-open {
  display:none;
}

.menu-item,
label.menu-open-button {
  @extend %ball;
  font-size: 16px;
}

label.menu-open-button {
  z-index: $footer-zindex + 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition-duration: 400ms;
  cursor:pointer;
  margin:0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.app-shortcut-icon {
  width: $menu-grid-icon;
  height: $menu-grid-icon;
  background: #ecf0f1;
  color: #ecf0f1;
  transition: .3s;
  box-shadow: -8px -8px, 0 -8px, 8px -8px, -8px 0, 8px 0, -8px 8px, 0 8px, 8px 8px;
}

.menu-open:checked+.menu-open-button{
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.9,0.9) translate3d(0,0,0);
  background: $fusion-500;

  .app-shortcut-icon {
    box-shadow: 0 -5px, 0 -8px, 5px 0, -5px 0, 8px 0, -8px 0, 0 8px, 0 5px !important;
    -webkit-transform: rotate3d(0,0,1,-45deg) scale3d(.8,.8,.8);
    transform: rotate3d(0,0,1,-45deg) scale3d(.8,.8,.8);
  }
}

.menu-open:checked~.menu-item{
  transition-timing-function:cubic-bezier(0.165, 0.840, 0.440, 1.000);

    @if $menu-item-direction == 'top' {

      @for $i from 1 through $menu-items{
        &:nth-child(#{$i+2}){
          transition-duration:50ms+(100ms*$i);
          transform:translate3d(0,-($menu-item-size+3)*$i,0);
        }
      }

    } @else {

      @for $i from 1 through $menu-items{
        &:nth-child(#{$i+2}){
          transition-duration:50ms+(100ms*$i);
          transform:translate3d(-($menu-item-size+3)*$i,0,0);
        }
      }
    }
}